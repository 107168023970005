$pe-7s-font-path: "/fonts" !default;
$font-size-base: 1em !default;
$font-prefix: "pe-7s" !default;

$font-var-album: "\e6aa";
$font-var-arc: "\e6ab";
$font-var-back-2: "\e6ac";
$font-var-bandaid: "\e6ad";
$font-var-car: "\e6ae";
$font-var-diamond: "\e6af";
$font-var-door-lock: "\e6b0";
$font-var-eyedropper: "\e6b1";
$font-var-female: "\e6b2";
$font-var-gym: "\e6b3";
$font-var-hammer: "\e6b4";
$font-var-headphones: "\e6b5";
$font-var-helm: "\e6b6";
$font-var-hourglass: "\e6b7";
$font-var-leaf: "\e6b8";
$font-var-magic-wand: "\e6b9";
$font-var-male: "\e6ba";
$font-var-map-2: "\e6bb";
$font-var-next-2: "\e6bc";
$font-var-paint-bucket: "\e6bd";
$font-var-pendrive: "\e6be";
$font-var-photo: "\e6bf";
$font-var-piggy: "\e6c0";
$font-var-plugin: "\e6c1";
$font-var-refresh-2: "\e6c2";
$font-var-rocket: "\e6c3";
$font-var-settings: "\e6c4";
$font-var-shield: "\e6c5";
$font-var-smile: "\e6c6";
$font-var-usb: "\e6c7";
$font-var-vector: "\e6c8";
$font-var-wine: "\e6c9";
$font-var-cloud-upload: "\e68a";
$font-var-cash: "\e68c";
$font-var-close: "\e680";
$font-var-bluetooth: "\e68d";
$font-var-cloud-download: "\e68b";
$font-var-way: "\e68e";
$font-var-close-circle: "\e681";
$font-var-id: "\e68f";
$font-var-angle-up: "\e682";
$font-var-wristwatch: "\e690";
$font-var-angle-up-circle: "\e683";
$font-var-world: "\e691";
$font-var-angle-right: "\e684";
$font-var-volume: "\e692";
$font-var-angle-right-circle: "\e685";
$font-var-users: "\e693";
$font-var-angle-left: "\e686";
$font-var-user-female: "\e694";
$font-var-angle-left-circle: "\e687";
$font-var-up-arrow: "\e695";
$font-var-angle-down: "\e688";
$font-var-switch: "\e696";
$font-var-angle-down-circle: "\e689";
$font-var-scissors: "\e697";
$font-var-wallet: "\e600";
$font-var-safe: "\e698";
$font-var-volume2: "\e601";
$font-var-volume1: "\e602";
$font-var-voicemail: "\e603";
$font-var-video: "\e604";
$font-var-user: "\e605";
$font-var-upload: "\e606";
$font-var-unlock: "\e607";
$font-var-umbrella: "\e608";
$font-var-trash: "\e609";
$font-var-tools: "\e60a";
$font-var-timer: "\e60b";
$font-var-ticket: "\e60c";
$font-var-target: "\e60d";
$font-var-sun: "\e60e";
$font-var-study: "\e60f";
$font-var-stopwatch: "\e610";
$font-var-star: "\e611";
$font-var-speaker: "\e612";
$font-var-signal: "\e613";
$font-var-shuffle: "\e614";
$font-var-shopbag: "\e615";
$font-var-share: "\e616";
$font-var-server: "\e617";
$font-var-search: "\e618";
$font-var-film: "\e6a5";
$font-var-science: "\e619";
$font-var-disk: "\e6a6";
$font-var-ribbon: "\e61a";
$font-var-repeat: "\e61b";
$font-var-refresh: "\e61c";
$font-var-add-user: "\e6a9";
$font-var-refresh-cloud: "\e61d";
$font-var-paperclip: "\e69c";
$font-var-radio: "\e61e";
$font-var-note2: "\e69d";
$font-var-print: "\e61f";
$font-var-network: "\e69e";
$font-var-prev: "\e620";
$font-var-mute: "\e69f";
$font-var-power: "\e621";
$font-var-medal: "\e6a0";
$font-var-portfolio: "\e622";
$font-var-like2: "\e6a1";
$font-var-plus: "\e623";
$font-var-left-arrow: "\e6a2";
$font-var-play: "\e624";
$font-var-key: "\e6a3";
$font-var-plane: "\e625";
$font-var-joy: "\e6a4";
$font-var-photo-gallery: "\e626";
$font-var-pin: "\e69b";
$font-var-phone: "\e627";
$font-var-plug: "\e69a";
$font-var-pen: "\e628";
$font-var-right-arrow: "\e699";
$font-var-paper-plane: "\e629";
$font-var-delete-user: "\e6a7";
$font-var-paint: "\e62a";
$font-var-bottom-arrow: "\e6a8";
$font-var-notebook: "\e62b";
$font-var-note: "\e62c";
$font-var-next: "\e62d";
$font-var-news-paper: "\e62e";
$font-var-musiclist: "\e62f";
$font-var-music: "\e630";
$font-var-mouse: "\e631";
$font-var-more: "\e632";
$font-var-moon: "\e633";
$font-var-monitor: "\e634";
$font-var-micro: "\e635";
$font-var-menu: "\e636";
$font-var-map: "\e637";
$font-var-map-marker: "\e638";
$font-var-mail: "\e639";
$font-var-mail-open: "\e63a";
$font-var-mail-open-file: "\e63b";
$font-var-magnet: "\e63c";
$font-var-loop: "\e63d";
$font-var-look: "\e63e";
$font-var-lock: "\e63f";
$font-var-lintern: "\e640";
$font-var-link: "\e641";
$font-var-like: "\e642";
$font-var-light: "\e643";
$font-var-less: "\e644";
$font-var-keypad: "\e645";
$font-var-junk: "\e646";
$font-var-info: "\e647";
$font-var-home: "\e648";
$font-var-help2: "\e649";
$font-var-help1: "\e64a";
$font-var-graph3: "\e64b";
$font-var-graph2: "\e64c";
$font-var-graph1: "\e64d";
$font-var-graph: "\e64e";
$font-var-global: "\e64f";
$font-var-gleam: "\e650";
$font-var-glasses: "\e651";
$font-var-gift: "\e652";
$font-var-folder: "\e653";
$font-var-flag: "\e654";
$font-var-filter: "\e655";
$font-var-file: "\e656";
$font-var-expand1: "\e657";
$font-var-exapnd2: "\e658";
$font-var-edit: "\e659";
$font-var-drop: "\e65a";
$font-var-drawer: "\e65b";
$font-var-download: "\e65c";
$font-var-display2: "\e65d";
$font-var-display1: "\e65e";
$font-var-diskette: "\e65f";
$font-var-date: "\e660";
$font-var-cup: "\e661";
$font-var-culture: "\e662";
$font-var-crop: "\e663";
$font-var-credit: "\e664";
$font-var-copy-file: "\e665";
$font-var-config: "\e666";
$font-var-compass: "\e667";
$font-var-comment: "\e668";
$font-var-coffee: "\e669";
$font-var-cloud: "\e66a";
$font-var-clock: "\e66b";
$font-var-check: "\e66c";
$font-var-chat: "\e66d";
$font-var-cart: "\e66e";
$font-var-camera: "\e66f";
$font-var-call: "\e670";
$font-var-calculator: "\e671";
$font-var-browser: "\e672";
$font-var-box2: "\e673";
$font-var-box1: "\e674";
$font-var-bookmarks: "\e675";
$font-var-bicycle: "\e676";
$font-var-bell: "\e677";
$font-var-battery: "\e678";
$font-var-ball: "\e679";
$font-var-back: "\e67a";
$font-var-attention: "\e67b";
$font-var-anchor: "\e67c";
$font-var-albums: "\e67d";
$font-var-alarm: "\e67e";
$font-var-airplay: "\e67f";
